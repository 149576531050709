import React from 'react'
import { graphql } from 'gatsby'
import { readableColor } from 'polished'
import styled from 'styled-components'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'

const PBox = styled(AnimatedBox)`
  max-width: 1000px;
  margin: 0 auto;
`

const PButton = styled(Button)<{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
`

type PageProps = {
  data: {
    curriculum: {
      nodes: {
        name: string
        localFile: {
          publicURL: string
        }
      }[]
    }
    aboutText: {
      html: string
    }
  }
}

const About: React.FunctionComponent<PageProps> = ({ data: { curriculum, aboutText } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  const { html } = aboutText

  return (
    <Layout>
      <SEO title="About | ToriArt" desc="About Toriart!" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </AnimatedBox>
      <PBox style={{ textAlign: 'center' }} py={10} px={[6, 6, 8, 10]} />
    </Layout>
  )
}

export default About

export const query = graphql`
  query About {
    curriculum: allDropboxNode(filter: { name: { eq: "toriart_cv.pdf" } }) {
      nodes {
        name
        localFile {
          publicURL
        }
      }
    }
    aboutText: markdownRemark(frontmatter: { title: { eq: "About Toriart" } }) {
      html
    }
  }
`
